<section id='centralita'>
    <div class="container">
        <div class="welcome">
            <i class="bi bi-headphones text-gradient" data-aos="fade-in" data-aos-duration="1500"></i>
            <h2 data-aos="fade-in" data-aos-duration="1500"><strong class="color-green">CENTRALITA
                    VIRTUAL</strong> <br>para empresas de todos los tamaños</h2>

            <div class="divider" data-aos="fade-left" data-aos-duration="2500"></div>

            <p>
                Profesionaliza la atención telefónica de tu negocio y otorga a tus comunicaciones <br> un plus de valor
                añadido
                con +20 servicios adicionales.<br>
                Nuestra Centralita IP es una solución sencilla y competitiva con todas las funcionalidades <br>
                necesarias para la PYME que, además, cubrirá vuestras necesidades <br> con una gestión integral que
                repercutirá en la optimización del tiempo y la reducción de costes. <br>
            </p>
        </div>
        <div class="schema" data-aos="fade-down">
            <div class="schema-boxes">
                <img class="schema-img" src="../../../assets/IMG/Logos-Golf/Logo_GolfMobile1.png" alt="golfmobile">
            </div>

            <div class="icondown" data-aos="fade-down">
                <i class="bi bi-arrow-down "></i>
            </div>

            <div class="schema-boxes" data-aos="fade-down">
                <img class="schema-img" src="../../../../assets/IMG/infoempresas/img-router.png" alt="">
                <h3>Router red GSM</h3>
            </div>

            <div class="icondown" data-aos="fade-down">
                <i class="bi bi-arrow-down "></i>
            </div>

            <div class="schema-services" data-aos="fade-down">
                <div class="schema-boxes">
                    <img src="../../../../assets/IMG/infoempresas/img-plataformas.png" alt="" class="schema-img-small">
                    <h3>Terminales IP fijas e inalámbricas</h3>
                </div>

                <div class="schema-boxes">
                    <img src="../../../../assets/IMG/infoempresas/img-IP.png" alt="" class="schema-img-small">
                    <h3>Extensiones registradas en Softphone</h3>
                </div>


                <div class="schema-boxes">
                    <img src="../../../../assets/IMG/infoempresas/img-sim.png" alt="" class="schema-img-small">
                    <h3>
                        Enrutado y/o transferencia de llamadas a moviles internos o externos.</h3>
                </div>

                <!-- <div class="schema-boxes">
                    <img src="../../../../assets/IMG/infoempresas/img-puzzle.png" alt="" class="schema-img-small">
                    <h3>CRM ERP</h3>
                </div> -->
            </div>
        </div>

        <div class="iconsouth" data-aos="fade-in">
            <mat-icon class="icon-south">south</mat-icon>
        </div>

        <!-- servicios -->
        <div class="services">
            <div class="services-boxes" data-aos="fade-right">
                <div class="icon-box">
                    <mat-icon>insights</mat-icon>
                </div>
                <div class="title">
                    <span>
                        Disminución de costes
                    </span>
                </div>
                <p>Con nuestra solución de centralita en la nube, podrá disminuir los costes en telefonía,
                    dando solución a las comunicaciones de su negocio con un servicio difereniado y de calidad.</p>
            </div>

            <div class="services-boxes" data-aos="fade-left">
                <div class="icon-box">
                    <mat-icon>bolt</mat-icon>
                </div>
                <div class="title">
                    <span>
                        Rápido y sencillo
                    </span>
                </div>
                <p>De una forma rápida y sencilla, usted podrá empezar a disfrutar en su
                    empresa de todas las funcionalidades de la centralita virtual</p>
            </div>

            <div class="services-boxes" data-aos="fade-right">
                <div class="icon-box">
                    <mat-icon>all_inclusive</mat-icon>
                </div>
                <div class="title">
                    <span>
                        Gestión integral
                    </span>
                </div>
                <p>Nos ocupamos de la gestión de portabilidad de sus numeraciones o si
                    el cliente lo desea puede solicitarnos numeraciones nuevas.</p>
            </div>

            <div class="services-boxes" data-aos="fade-left">
                <div class="icon-box">
                    <mat-icon>call_split</mat-icon>
                </div>
                <div class="title">
                    <span>
                        Sin portabilidad
                    </span>
                </div>
                <p>Su empresa podrá integrar todas las funcionalidades avanzadas de una centralita IP
                    con su sistema de telefonía móvil, sin necesidad de portar números o cambios de operador móvil.</p>
            </div>
        </div>

        <div class="iconsouth" data-aos="fade-down">
            <mat-icon class="icon-south">south</mat-icon>
        </div>


        <!-- servicios extras -->
        <div class="extras">
            <div class="extra align-end">
                <div *ngFor="let item of list1" class="items" data-aos="fade-right">
                    <h2 class="text-end">
                        {{item.text}}
                    </h2>
                    <mat-icon class="text-gradient icon">{{item.icon}}</mat-icon>
                </div>
            </div>
            <div class="extra">
                <div *ngFor="let item of list2" class="items" data-aos="fade-left">
                    <mat-icon class="text-gradient icon">{{item.icon}}</mat-icon>
                    <h2>
                        {{item.text}}
                    </h2>
                </div>
            </div>
        </div>

    </div>
</section>