import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { ContactEmail } from "src/app/models/contact.interface";
import { CloudPBX } from "src/app/models/corporate.interface";
import { Pack } from "src/app/models/pack.interface";
import { APIService } from "src/app/services/api.service";
import { CartService } from "src/app/services/cart.service";
import { SelectorService } from "src/app/services/selector.service";
import customer_email from "../../../templates/customer_email";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit, OnDestroy {
  packs: Pack[] = [];
  cloudPBX: CloudPBX | null = null;
  subs: Subscription | null = null;
  showForm = false;
  contractForm: FormGroup;
  showCart = false;
  disableConfirmButton = false;

  body_email: ContactEmail;

  constructor(
    private selectorService: SelectorService,
    private _fb: FormBuilder,
    private cartService: CartService,
    private api_service: APIService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.subs = this.selectorService.cart.subscribe((data) => {
      this.packs = data.packs;
      this.cloudPBX = data.cloudPBX ? data.cloudPBX : null;
    });
    this.contractForm = this._fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required]],
      zip: ["", [Validators.required]],
    });
    this.cartService.showCart.subscribe((state) => (this.showCart = state));
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }

  get f() {
    return this.contractForm.controls;
  }

  getTotal() {
    let total = 0;
    if (this.packs.length > 0)
      total += this.packs
        .map((p) => p.units! * p.price)
        .reduce((a, b) => a + b);
    if (this.cloudPBX) total += this.cloudPBX.price;
    return total.toFixed(2);
  }

  getSubtotal(pack: Pack) {
    return (pack.units! * pack.price).toFixed(2);
  }

  removePack(pack: Pack) {
    this.selectorService.removeFromCart(pack);
  }

  removeCloudPBX() {
    this.selectorService.removeCloudPBXFromCart();
  }

  closeCart() {
    this.cartService.toggleCart();
  }
  onSubmit() {
    if (!this.contractForm.valid) {
      this._snackBar.open("Ups. Debe rellenar los campos", "OK", {
        duration: 1500,
      });
      return;
    }
    let email_to_customer_body: ContactEmail = this.emailToCustomerBody();
    this.sendEmail(email_to_customer_body);

    let email_to_support_body: ContactEmail = this.emailToSupportBody();
    this.sendEmail(email_to_support_body);
  }

  emailToCustomerBody() {
    let to: string = this.f.email.value;
    let subject: string = "Bienvenido a GOLFMOBILE";
    let text: string =
      "<h2><strong>" +
      this.f.name.value +
      "</strong></h2><h4>" +
      this.f.email.value +
      "</h4><h4>" +
      this.f.phone.value +
      "</h4><p>" +
      this.f.zip.value +
      "</p>";

    let purchase_string: string = this.createPurchaseString();
    let customer_email_replaced = customer_email
      .replace("CLIENTEREGISTRADO", this.f.name.value)
      .replace("PURCHASELISTHTML", purchase_string);
    let html: string = customer_email_replaced;

    let contract_body: ContactEmail = {
      to: to,
      subject: subject,
      text: text,
      html: html,
    };
    return contract_body;
  }

  emailToSupportBody() {
    let to: string = "contacto@nexotelecom.es";
    let subject: string =
      this.f.name.value + " quiere contratar uno de nuestros productos";
    let text: string =
      "<h2><strong>" +
      this.f.name.value +
      "</strong></h2><h4>" +
      this.f.email.value +
      "</h4><h4>" +
      this.f.phone.value +
      "</h4><p>" +
      this.f.zip.value +
      "</p>";

    let purchase_string: string = this.createPurchaseString();
    let html: string =
      "<h2>Nombre del cliente: <strong>" +
      this.f.name.value +
      "</strong></h2><h4>Correo electrónico: " +
      this.f.email.value +
      "</h4><h4>Número de teléfono: " +
      this.f.phone.value +
      "</h4><h4>Código Postal: " +
      this.f.zip.value +
      "</h4><h4>Productos contratados: </h4><div>" +
      purchase_string +
      "</div>";

    let contract_body: ContactEmail = {
      to: to,
      subject: subject,
      text: text,
      html: html,
    };
    return contract_body;
  }

  createPurchaseString() {
    // Cliente
    let purchase_string: string = "";
    for (let line of this.packs) {
      let substring: string =
        "<li> " +
        line.items.lines +
        " línea móvil " +
        line.name +
        " " +
        line.price +
        "€ </li>";
      purchase_string += substring;
    }

    // Empresa
    if (this.cloudPBX) {
      let substring: string =
        "<li> Servicio Cloud PBX " +
        this.cloudPBX.name +
        " " +
        this.cloudPBX.price +
        "€ </li>";
      purchase_string += substring;
    }

    return purchase_string;
  }

  sendEmail(body: ContactEmail) {
    this.api_service.sendEmail(body).subscribe(
      (res) => {
        console.log(res);

        this._snackBar.open(
          "Muchas gracias, nos pondremos en contacto ;)",
          "Aceptar",
          {
            duration: 4000,
          }
        );
        this.contractForm.reset();
        this.disableConfirmButton = true;
      },
      (err) => {
        console.log(err.error);
        this._snackBar.open(
          "Upss, algo salió mal. Vuelve a intentar",
          "Aceptar",
          {
            duration: 2000,
          }
        );
      }
    );
  }
}
