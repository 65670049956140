import { Component, OnInit, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ContactEmail } from "src/app/models/contact.interface";
import { APIService } from "src/app/services/api.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  showModal: boolean;

  constructor(
    private _fb: FormBuilder,
    private api_service: APIService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.contactForm = this._fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      phone_number: ["", [Validators.required]],
      text_input: ["", [Validators.required, Validators.maxLength(1000)]],
      acceptlegal: [false, [Validators.required]],
    });
  }

  submit() {
    if (!this.contactForm.valid) {
      this._snackBar.open("Upss. Debe completar los campos", "Aceptar", {
        duration: 1500,
      });
      return;
    }
    let to: string = "contacto@nexotelecom.es";

    let subject: string =
      this.f.name.value +
      " quiere contactar con nosotros a través de golfmobiles.es";
    let text: string = this.f.text_input.value;
    let html: string =
      "<h2><strong>" +
      this.f.name.value +
      "</strong></h2><h4>" +
      this.f.email.value +
      "</h4><h4>" +
      this.f.phone_number.value +
      "</h4><p>" +
      this.f.text_input.value +
      "</p>";

    // create the body for the API

    let contact_body: ContactEmail = {
      to: to,
      subject: subject,
      text: text,
      html: html,
    };

    this.api_service.sendEmail(contact_body).subscribe(
      (res) => {
        let success: string = res.split(":")[0];
        this._snackBar.open(success, "Aceptar", {
          duration: 2000,
        });
        this.reset();
      },
      (err) => {
        console.log(err.error);
      }
    );
  }

  reset() {
    this.contactForm.reset();
  }

  get f() {
    return this.contactForm.controls;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LegalDialog, {});
  }
}

@Component({
  selector: "legal-dialog",
  templateUrl: "./legal-dialog.html",
})
export class LegalDialog {
  constructor(public dialogRef: MatDialogRef<LegalDialog>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
