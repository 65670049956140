import { Pack, PackType } from "../models/pack.interface";

const movil: Pack[] = [
  {
    name: "TARIFA BENJAMIN",
    price: 6.9,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "2GB",
      fiberSpeed: 0,
      extra: ["150 minutos"],
    },
  },
  {
    name: "TARIFA ALEVIN",
    price: 7.9,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "100MB DE DATOS",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "TARIFA INFANTIL",
    price: 9.9,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "10GB + 2GB DE DATOS",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "INFANTIL +",
    price: 10.9,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "14GB DE DATOS",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "TARIFA CADETE",
    price: 14.9,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "50Gb + 10Gb. OFERTA HASTA 30/04",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
];

const movilHATTRICK: Pack[] = [
  {
    name: "TARIFA BIRDIE",
    price: 19.9,
    type: PackType.MOVIL,
    items: {
      lines: 3,
      landline: false,
      mobileData: "40GB DE DATOS A COMPARTIR",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "TARIFA PAR",
    price: 24.9,
    type: PackType.MOVIL,
    items: {
      lines: 3,
      landline: false,
      mobileData: "60GB DE DATOS A COMPARTIR",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "TARIFA SENSACIONAL",
    price: 29.9,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "100GB DE DATOS",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "TARIFA DOBLE BOGIE",
    price: 34.9,
    type: PackType.MOVIL,
    items: {
      lines: 3,
      landline: false,
      mobileData: "120GB DE DATOS A COMPARTIR",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
];

const convergente: Pack[] = [
  {
    name: "PACK MADERA",
    price: 34.89,
    type: PackType.PACK,
    items: {
      lines: 1,
      landline: true,
      mobileData: "10GB + 10GB. OFERTA HASTA 30/04",
      fiberSpeed: 100,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK HÍBRIDO",
    price: 39.89,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "40GB. OFERTA HASTA 30/04",
      fiberSpeed: 100,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "ALL SQUARE",
    price: 44.9,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "100",
      fiberSpeed: 300,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK HÍBRIDO PLUS",
    price: 59.89,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "80GB A COMPARTIR",
      fiberSpeed: 100,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK CADDIE",
    price: 64.9,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "80GB",
      fiberSpeed: 600,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK GREENKEEPER",
    price: 49.89,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "40GB. OFERTA HASTA 30/04",
      fiberSpeed: 600,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK MARSHALL",
    price: 44.89,
    type: PackType.PACK,
    items: {
      lines: 1,
      landline: true,
      mobileData: "10GB + 10Gb. OFERTA HASTA 30/04",
      fiberSpeed: 600,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK LIGA",
    price: 74.8,
    type: PackType.PACK,
    items: {
      lines: 4,
      landline: true,
      mobileData: "140GB A COMPARTIR",
      fiberSpeed: 100,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK LIGA PLUS",
    price: 79.9,
    type: PackType.PACK,
    items: {
      lines: 4,
      landline: true,
      mobileData: "140GB A COMPARTIR",
      fiberSpeed: 600,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK DRIVER",
    price: 54.89,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "40GB A COMPARTIR",
      fiberSpeed: 1000,
      extra: ["Llamadas ilimitadas"],
    },
  },
];

const fibra: Pack[] = [
  {
    name: "FIBRA PUTT",
    price: 29.9,
    type: PackType.FIBRA,
    items: {
      lines: 0,
      landline: false,
      fiberSpeed: 300,
      extra: ["OFERTA HASTA 30/04"],
    },
  },
  {
    name: "FIBRA WEDGE",
    price: 39.9,
    type: PackType.FIBRA,
    items: {
      lines: 0,
      landline: false,
      fiberSpeed: 600,
    },
  },

  {
    name: "ROUTER 4G/ MIFI 4G",
    price: 49.9,
    type: PackType.FIBRA,
    items: {
      lines: 0,
      landline: false,
      fiberSpeed: 0,
      extra: ["Datos interminables*. Sin permanencia"],
    },
  },
];

export const packs: Pack[] = [
  ...movil,
  ...movilHATTRICK,
  ...convergente,
  ...fibra,
];
