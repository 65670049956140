import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { packs } from '../data/packsData';
import { Cart } from '../models/cart';
import { Pack } from '../models/pack.interface';
import { CloudPBX } from '../models/corporate.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SelectorService {
  cart = new BehaviorSubject<Cart>({ packs: [] });
  URL_WORDPRESS = `https://nexo.es/wp-json/wc/v3/products?tag=49`
  packs_API = []

  constructor(private readonly http: HttpClient) {}

  getAllPacks() {
    return packs;
  }
  getPackAll(params):any {
    return this.http.get<any[]>(`${this.URL_WORDPRESS}${params}`,{headers: new HttpHeaders().set('Authorization', environment.apiWP_token)})
  }

  addToCart(pack: Pack) {
    const cartobj = this.cart.value;
    const packs = cartobj.packs;
    const index = packs.findIndex((op) => op.name === pack.name);
    if (index < 0) {
      pack.units = 1;
      packs.push(pack);
      this.cart.next({ ...cartobj, packs });
      return;
    }
    if (!packs[index].units) packs[index].units = 1;
    else packs[index].units! += 1;
  }

  removeFromCart(pack: Pack) {
    const packs = this.cart.value.packs.filter((p) => p.name !== pack.name);
    this.cart.next({ ...this.cart.value, packs });
  }

  addCloudPBXToCart(cloudPBX: CloudPBX) {
    const values = this.cart.value;
    this.cart.next({ ...values, cloudPBX });
  }
  removeCloudPBXFromCart() {
    const packs = this.cart.value.packs;
    this.cart.next({ packs: [...packs] });
    console.log(this.cart.value);
  }
}
