import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialoffers',
  templateUrl: './specialoffers.component.html',
  styleUrls: ['./specialoffers.component.css']
})
export class SpecialoffersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
