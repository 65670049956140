import { Component, OnInit } from '@angular/core';
import { client_voice } from 'src/app/data/clientvoiceData';
import { ClientVoice } from 'src/app/models/clientvoice.interface';

@Component({
  selector: 'app-client-voice',
  templateUrl: './client-voice.component.html',
  styleUrls: ['./client-voice.component.scss'],
})
export class ClientVoiceComponent implements OnInit {
  panelOpenState = false;

  client_voice: ClientVoice[];
  visible_client_voice: ClientVoice[];
  expanded_client_voice: ClientVoice[];

  constructor() {}

  ngOnInit(): void {
    this.client_voice = client_voice;
    this.visible_client_voice = this.client_voice.filter(
      (item) => item.visible == true
    );
    this.expanded_client_voice = this.client_voice.filter(
      (item) => item.visible == false
    );
  }
}
