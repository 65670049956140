<section>
    <div class="description">
        <span class="description-text"><strong style="color: #95c11f">Servicios personalizados </strong>para empresas de
            todos los tamaños </span>
    </div>
    <div class="container">
        <div class="item">
            <i class="bi bi-headphones text-gradient icon"></i>
            <h3 class="title">CENTRALITA CLOUD</h3>
            <p class="text">Servicio global de IP para empresa,
                ofreciendo desde la compra e implantación de la
                tecnología necesaria, hasta el soporte y
                mantenimiento fundamentales.
            </p>

            <div class="letter-container">
                <button mat-stroked-button class="mas-info" (click)="goToAnchor('centralita')">+ info</button>
            </div>
        </div>
        <div class="item">
            <i class="bi bi-wifi text-gradient icon"></i>
            <h3 class="title">INTERNET</h3>
            <p class="text">Fibra. Internet 4G. 5G. Colaboramos con los principales operadores nacionales
                de telecomunicaciones para ofrecer a nuestros clientes las mejores experiencias de conexión.
            </p>
        </div>

        <div class="item">
            <i class="bi bi-people-fill text-gradient icon"></i>
            <h3 class="title">SOFTWARE DE GESTIÓN</h3>
            <p class="text">Ofrecemos programas de organización
                y gestión empresarial creados por nosotros
                en los que podrán gestionar tanto interna como externamente todas las acciones del día a día.
            </p>

            <div class="letter-container">
                <button mat-stroked-button class="mas-info" (click)="goToAnchor('software')">+ info</button>
            </div>
        </div>

        <div class="item">
            <i class="bi bi-phone text-gradient icon"></i>
            <h3 class="title">MÓVIL</h3>
            <p class="text">Somos operador móvil virtual, con la tecnología 4G y cobertura del 99% en
                el territorio nacional. Gran servicio y calidad al mejor precio.
            </p>
        </div>
        <div class="item">
            <i class="bi bi-shield-check text-gradient icon"></i>
            <h3 class="title">SEGURIDAD</h3>
            <p class="text">Trabajamos con los mejores fabricantes para ofrecer en el ámbito de la seguridad,
                las soluciones más profesionales para nuestros clientes. Todos nuestros datos viajan cifrados y de
                manera
                segura. Pregúntanos por lo
                que necesites y encontraremos la manera de adaptarlo a tu empresa. </p>
        </div>


        <div class="item">
            <i class="bi bi-cpu text-gradient icon"></i>
            <h3 class="title">VPN</h3>
            <p class="text">Disponemos de una amplia gama de terminales testeados para los clientes. Podrán disponer de
                todas
                las comunicaciones
                bajo la misma estructura para evitar problemas.
            </p>
        </div>
        <div class="item">
            <i class="bi bi-chat-left-text text-gradient icon"></i>
            <h3 class="title">SMS MASIVOS</h3>
            <p class="text">Plataforma de envío de SMS masivos para gestionar mejor tu cartera de clientes.</p>
        </div>
        <div class="item">
            <i class="bi bi-terminal text-gradient icon"></i>
            <h3 class="title">DESARROLLO</h3>
            <p class="text"> Nuestro equipo de ingenieros trabaja día a día en el desarrollo de nuevo software para
                cubrir las
                necesidades de
                nuestros clientes. Nos adaptamos al 100% a vosotros.
            </p>
        </div>
    </div>


</section>
<app-infoempresas id='centralita'></app-infoempresas>
<app-infointernet id='software'></app-infointernet>