<section>
    <div style="max-height: 750px;">
        <h1 style="text-align: center;">Información básica sobre protección de
            datos</h1>

        <p><strong>Responsable:</strong> NEXO GLOBAL MANAGEMENT, S.L.<br>
            <strong> Finalidad:</strong> Atender su solicitud y enviarle comunicaciones comerciales (en caso de
            aceptar la casilla).<br>
            <strong>Legitimación:</strong> Ejecución de un contrato, consentimiento del interesado, interés legítimo
            del Responsable. <br>
            <strong>Destinatarios:</strong>Están previstas cesiones de datos a: Consultora de protección de datos,
            Proveedores web.<br>
            <strong>Derechos:</strong> Tiene derecho a acceder, rectificar y suprimir los datos, así como otros
            derechos, indicados en la información adicional, que puede ejercer dirigiéndose a
            atencion.cliente@oratioomobile.com o CL NARCIS MONTURIOL I ESTARRIOL, 4 EDIFICIO DESTRO OFICINA 22A PARQUE
            TECNOLÓGICO 46980-PATERNA (VALENCIA).<br>
            <strong>Procedencia:</strong> El propio interesado.<br>
            <strong>Información adicional:</strong> En la página web www.nexotelecom.es. </p>
    </div>

    <div style="display:flex; justify-content: center;">
        <button style="color:white; background-color: #95c11f; width: 70px; height: 30px;
      border-color:none; cursor: pointer; outline: none; border: #95c11f; border-radius: 6%;"
            (click)="onNoClick()">OK</button>
    </div>

</section>