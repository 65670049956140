<section class='check-net-dialog'>
    <mat-dialog-content>
        <div class="content">
            <div *ngIf='response.length>0; else noResponse'>
                <h1 style="font-weight: bold;">¡Tu dirección tiene cobertura de fibra!</h1>
                <h2>{{response[0].street_type}} {{response[0].street_name}} {{response[0].street_number}}.
                    {{response[0].post_code}}, {{response[0].town}}
                    ({{response[0].province}})</h2>
                <div class='buttons'>
                    <button class='contact-button' mat-raised-button color="accent" [mat-dialog-close]="true"
                        routerLink='/tariff-selector'>¡Genial!
                        Veamos vuestros productos</button>
                </div>
                <!-- <h2 style="margin-bottom: 0%;">No, gracias, prefiero seguir mirando</h2> -->
                <div class='buttons'>
                    <button class='contact-button' mat-button color="accent" [mat-dialog-close]="true">Prefiero seguir
                        navegando</button>
                </div>
            </div>
            <ng-template #noResponse>
                <h1>Vaya! Parece que no podemos verificar si hay cobertura</h1>
                <br>
                <h2>¿Quieres que lo verifiquemos por ti?</h2>
                <form class="email-form" [formGroup]="emailForm" (ngSubmit)="sendEmail()">
                    <mat-form-field class="col-12 example-full-width">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name" placeholder="Ingresa tu nombre">
                        <mat-error *ngIf="f.name.hasError('required')">
                            Por favor, ingresa tu nombre para continuar
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-12 example-full-width">
                        <mat-label>Número de contacto</mat-label>
                        <input matInput formControlName="phone" placeholder="011235813">
                        <mat-error *ngIf="f.phone.hasError('required')">
                            Por favor, ingresa un número de teléfono para continuar
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-12 example-full-width">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email" placeholder="ejemplo@ejemplo.com">
                        <mat-error *ngIf="f.email.hasError('email') || f.email.hasError('required')">
                            Por favor, ingresa un email válido
                        </mat-error>
                    </mat-form-field>

                    <button
                        [disabled]="f.name.hasError('required') || f.name.hasError('required') || f.email.hasError('email') || f.email.hasError('required')"
                        mat-raised-button color="accent" type="submit" class='contact-button'>Enviar
                    </button>

                    <div class='contact-info'>
                        <h2>Tambien puedes ponerte en contacto con nosotros</h2>
                        <h4><i class="bi bi-envelope-fill text-gradient"></i> contacto@nexomobile.com</h4>
                        <h4><i class="bi bi-telephone-fill text-gradient"></i> 900813640</h4>
                    </div>

                    <!-- <h2 style="margin-bottom: 0%;">No, gracias, prefiero seguir mirando</h2> -->
                    <div class='buttons'>
                        <button class='contact-button' mat-button color="accent" [mat-dialog-close]="true">Prefiero
                            seguir navegando</button>
                    </div>
                </form>

            </ng-template>
        </div>
    </mat-dialog-content>
</section>