<div class="targets-fondo">
    <div class="pack">
        <h2 class="plan-name">{{supp_tariff.name}}</h2>
        <div class="target">
            <div class="main-price">
                <h2 class="prices">{{supp_tariff.price}}'
                </h2>
                <div style="display:flex; flex-direction: column;">
                    <h5 class="decimals">{{supp_tariff.price}}</h5>
                    <h6 class="IVAinc">IVA inc</h6>
                </div>
            </div>
            <div class="desc">
                <div class="vertical-divider" id=vertical-divider>
                </div>
                <div class="pack-description" id="pack-description">
                    <span *ngIf="supp_tariff.assistance">
                        <mat-icon class="icons">local_phone</mat-icon><strong>{{supp_tariff.assistance}} hora/s de asistencia por conexión remota</strong>
                    </span>
                    <span *ngIf="supp_tariff.extra">
                        <mat-icon class="icons">add_box</mat-icon>{{supp_tariff.extra}}
                    </span>
                </div>
            </div>
            <div class="letter-container">
                <button class="lo-quiero" (click)="addToCart()">¡Lo quiero!</button>
            </div>
        </div>
    </div>
</div>