import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum WebType {
  EMPRESA = 'EMPRESA',
  PARTICULAR = 'PARTICULAR',
}
@Injectable({
  providedIn: 'root',
})
export class SlideToggleService {
  webType = new BehaviorSubject<string>(WebType.PARTICULAR);
  constructor() {}

  getWebType() {
    return this.webType.value;
  }

  change() {
    const webtype =
      this.webType.value === WebType.PARTICULAR
        ? WebType.EMPRESA
        : WebType.PARTICULAR;
    this.webType.next(webtype);
  }
}
