<header class="header">
    <div class="logo">
        <a routerLink="/">
            <img src="../../../assets/IMG/Logos-Golf/Logo_GolfMobile1.png" alt="">
        </a>
    </div>
    <nav class="nav-bar">
        <ul>
            <li>
                <span class="hover3" routerLink="/">Home</span>
            </li>
            <li>
                <span class="hover3" routerLink="/tariff-selector">Tarifas</span>
            </li>
            <li>
                <span class="hover3" routerLink="/empresas">Empresas</span>
            </li>
            <li>
                <span class="hover3" routerLink="/cobertura">Cobertura</span>
            </li>
            <li>
                <span class="hover3" routerLink="/contacta">Contacta</span>
            </li>
            <li>
                <button mat-icon-button mat-raised-button [matBadge]="badgeNumber" [matBadgeHidden]="badgeHidden"
                    matBadgeColor="accent" (click)="toggleCart()">
                    <mat-icon>shopping_cart</mat-icon>
                </button>
            </li>
        </ul>
    </nav>
    <div class="menu-btn">
        <button mat-icon-button [matMenuTriggerFor]="belowMenu">
            <mat-icon class="menu-icon">menu</mat-icon>
        </button>
        <button mat-icon-button mat-raised-button [matBadge]="badgeNumber" [matBadgeHidden]="badgeHidden"
            matBadgeColor="accent" (click)="toggleCart()">
            <mat-icon>shopping_cart</mat-icon>
        </button>
    </div>

    <mat-menu #belowMenu="matMenu" yPosition="below">
        <button mat-menu-item class="menu-item" [routerLink]="['/']">Home</button>
        <button mat-menu-item class="menu-item" [routerLink]="['/tariff-selector']">Tarifas</button>
        <button mat-menu-item class="menu-item" [routerLink]="['/empresas']">Empresas</button>
        <button mat-menu-item class="menu-item" [routerLink]="['/cobertura']">Cobertura</button>
        <button mat-menu-item class="menu-item" [routerLink]="['/contacta']">Contacta</button>
        <!-- <button mat-menu-item class="menu-item" (click)="toggleCart()">
            <mat-icon [matBadge]="badgeNumber" [matBadgeHidden]="badgeHidden" matBadgeColor="accent">shopping_cart
            </mat-icon>
        </button> -->
    </mat-menu>

</header>