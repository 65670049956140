import { ClientVoice } from "../models/clientvoice.interface";

export const client_voice: ClientVoice[] = [
    { 
        client: 'RACRISA',
        image: 'logo_racrisa.png',
        visible: true,
        message: 'Conseguimos solventar un problema de cobertura que tenían con su proveedor de móvil, además de cambiar la red fija a nuestra centralita avanzada, mejorando mucho los tiempos en cuanto a actividad en su día a día, además de conseguir un ahorro de más de un 50% en su facturación' 
    },
    { 
        client: 'VAQUETA Y PELAYO', 
        image: 'logo_vaquetaypelayo.png', 
        visible: true,
        message: 'Acompañamos desde sus aperturas a estos dos grandes restaurantes, dotándolos de servicios a precios inmejorables y en ambos con nuestra centralita avanzada, facilitando mucho la parte de reservas en cuanto horarios o días de cierre con cada una de sus locuciones correspondientes'
    },
    { 
        client: 'MISTELA Y SASTRERIA', 
        image: 'logo_mistela.png', 
        visible: true,
        message: 'Acompañamos desde sus aperturas a estos dos grandes restaurantes, dotándolos de servicios a precios inmejorables y en ambos con nuestra centralita avanzada, facilitando mucho la parte de reservas en cuanto horarios o días de cierre con cada una de sus locuciones correspondientes' 
    },
    { 
        client: 'COMELSA', 
        image: 'logo_comelsa.png', 
        visible: true,
        message: 'En plena pandemia hicimos el cambio a nuestro operador, con soluciones adecuadas a la situación actual, debido a que se hizo una solución ad hoc, pudiendo dejar totalmente optimizadas sus comunicaciones tanto internas como externas' 
    },
    { 
        client: 'GLOBAL ROBOT EXPO', 
        image: 'logo_GlobalRobot.png', 
        visible: false,
        message: 'Esta empresa referente a nivel internacional lleva confiando en nuestros servicios desde hace unos años, serviciando todas sus telecomunicaciones en la empresa' 
    },
    { 
        client: 'SAGARMANTA', 
        image: 'logo_sagarmanta.png', 
        visible: false,
        message: 'Empresa referente en eventos a nivel nacional confía desde hace años en nosotros, no solo para serviciar todas sus telecomunicaciones durante todo el año, sino para aquellos eventos a los que requieren de servicios de datos para días concretos'
    },
    { 
        client: 'ROTOTOM', 
        image: 'Logo_Rototom.png', 
        visible: false,
        message: 'Este festival entre los TOP 10 con más asistentes de España confía en nuestros servicios a nivel global' 
    },
    { 
        client: 'FACIAL CLINIQUE', 
        image: 'logo_FacialClinique.png', 
        visible: false,
        message: 'A este cliente conseguimos ahorrarle, hace ya más de 4 años, más de un 60% en su facturación, mejorando la conexión de fibra entre todas sus clínicas, además de implementarle nuestra centralita avanzada, consiguiendo con ello optimizar la comunicación entre todas las sedes, además de, con todos los servicios que permite la misma, optimizar tanto la atención de cara a los clientes, viéndose un aumento en las ventas' 
    },
    { 
        client: 'ONICE GLOBAL', 
        image: 'logo_onice.png', 
        visible: false,
        message: 'Gracias a nuestro Software ERP a esta empresa le solventamos el control horario de trabajadores, así como de la gestión en obras con un mayor control en tiempos y recursos, así de como conseguir, incluso implementando este sistema, conseguir un ahorro en torno al 40% con respecto a su anterior operador' 
    },
    { 
        client: 'JV LIMPIEZAS', 
        image: 'logo_JV.png', 
        visible: false,
        message: 'Gracias a nuestro Software ERP a esta empresa le solventamos el control horario de trabajadores, así como de la gestión en obras con un mayor control en tiempos y recursos, así de como conseguir, incluso implementando este sistema, conseguir un ahorro en torno al 50% con respecto a su anterior operador' 
    }
]
