<section>
    <div class="info-container">

        <div class="phone-info">
            <i class="bi bi-telephone-inbound"></i>
            <a class="phone" href="tel:+34960835293">96 083 5293</a>
        </div>
    </div>
    <div class="divider-container">
        <div class="divider"></div>
    </div>
</section>