<div class="targets-fondo">
    <div class="pack">
        <h2 class="plan-name">{{cloud_pbx_tariff.name}}</h2>
        <div class="target">
            <div class="main-price">
                <h2 class="prices">{{getInteger(cloud_pbx_tariff.price)}}'
                </h2>
                <div style="display:flex; flex-direction: column;">
                    <h5 class="decimals">{{getDecimal(cloud_pbx_tariff.price)}}</h5>
                    <h6 class="IVAinc">IVA inc</h6>
                </div>
            </div>
            <div class="desc">
                <div class="vertical-divider" id=vertical-divider>
                </div>
                <div class="pack-description" id="pack-description">

                    <span *ngIf="cloud_pbx_tariff.ddi">
                        <div *ngIf="cloud_pbx_tariff.ddi==1; else plural">
                            <mat-icon class="icons">local_phone</mat-icon><strong>{{cloud_pbx_tariff.ddi}} DDI
                                geografico nacional</strong>
                        </div>
                        <ng-template #plural>
                            <mat-icon class="icons">local_phone</mat-icon><strong>{{cloud_pbx_tariff.ddi}} DDI
                                geograficos nacionales</strong>
                        </ng-template>
                    </span>

                    <span *ngIf="cloud_pbx_tariff.minutes_landline">
                        <mat-icon class="icons">add_box</mat-icon><strong>{{cloud_pbx_tariff.minutes_landline}} minutos
                            a fijos nacionales</strong>
                    </span>

                    <span *ngIf="cloud_pbx_tariff.minutes_mobile">
                        <mat-icon class="icons">add_box</mat-icon><strong>{{cloud_pbx_tariff.minutes_mobile}} minutos a
                            móviles nacionales</strong>
                    </span>

                    <span *ngIf="cloud_pbx_tariff.concurrent_calls">
                        <mat-icon class="icons">add_box</mat-icon><strong>{{cloud_pbx_tariff.concurrent_calls}} llamadas
                            simultáneas</strong>
                    </span>

                    <span *ngIf="cloud_pbx_tariff.fax>0">
                        <mat-icon class="icons">add_box</mat-icon><strong>{{cloud_pbx_tariff.fax}} Fax</strong>
                    </span>

                    <span *ngIf="cloud_pbx_tariff.switchboard">
                        <mat-icon class="icons">add_box</mat-icon><strong>Todas las funcionalidades de nuestra
                            centralita virtual</strong>
                    </span>

                    <span *ngIf="cloud_pbx_tariff.control_panel">
                        <mat-icon class="icons">add_box</mat-icon><strong>Acceso a panel web usuario</strong>
                    </span>
                </div>
            </div>
            <div class="letter-container">
                <button class="lo-quiero" (click)="addToCart()">¡Lo quiero!</button>
            </div>
        </div>
    </div>
</div>