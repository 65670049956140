<h1 mat-dialog-title>Configuración</h1>
<div mat-dialog-content style="display: flex;flex-direction: column;min-height: 150px;">
    <mat-slide-toggle color='accent' (change)='propias = !propias'>
        Cookies Propias
    </mat-slide-toggle>
    <mat-slide-toggle color="accent" (change)='terceros = !terceros'>
        Cookies de terceros
    </mat-slide-toggle>
</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]='{propias:propias,terceros:terceros}'>Guardar</button>
    <button mat-button mat-dialog-close>Rechazar</button>
</div>