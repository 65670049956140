import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rrss',
  templateUrl: './rrss.component.html',
  styleUrls: ['./rrss.component.css']
})
export class RRSSComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
