import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-our-services",
  templateUrl: "./our-services.component.html",
  styleUrls: ["./our-services.component.scss"],
})
export class OurServicesComponent implements OnInit {
  constructor(private scroller: ViewportScroller) {}

  ngOnInit(): void {}

  goToAnchor(id: string) {
    this.scroller.scrollToAnchor(id);
  }
}
