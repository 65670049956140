import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SlideToggleService, WebType } from 'src/app/services/slide-toggle.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  suscription: Subscription;
  webParticular: boolean;
  constructor(private sliderToggle: SlideToggleService) {}

  ngOnInit(): void {
    this.suscription = this.sliderToggle.webType.subscribe((wt) => {
      this.webParticular = wt === WebType.PARTICULAR;
    });
  }
  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

}
