<section>

  <div class="container-schema">
    <h1 class="h1-class" data-aos="fade-in">CRM + Software de gestión</h1>
    <div class="second">
      <div class="iconsouth" data-aos="fade-in">
        <mat-icon class="icon-south">south</mat-icon>
      </div>
      <h1 class="subtitle" data-aos="fade-down" data-aos-duration="1500">BENEFICIOS</h1>

      <div class="result info" data-aos="fade-down" data-aos-duration="1500">
        <div class="result-item"><i class="bi bi-graph-up text-gradient"></i>
          <div>Incremento de ventas</div>
        </div>
        <div class="result-item"><i class="bi bi-clock-history text-gradient"></i>
          <div>Optimización de los tiempos de trabajo</div>
        </div>
        <div class="result-item"><i class="bi bi-cash-stack text-gradient"></i>
          <div>Reduccion de costes</div>
        </div>
      </div>
    </div>
  </div>

  <!--MOVIL-->
  <div class="container">
    <div class="title" data-aos="fade-in">
      <!-- <img src="/assets/IMG/infoempresas/img-puzzle.png" alt="CRM+ERP"> -->
      <h2 class="text-gradient">Software de gestión</h2>
    </div>


    <div class="content">
      <!-- 
      <div class="crm info" data-aos="fade-in">
        <div class="info-title">CRM</div>
        <div class="list">
          <h2>Servicio al cliente</h2>
          <h2>Gestión de la Comunidad</h2>
          <h2>Centro de llamadas</h2>
          <h2>Gestión relación-clientes</h2>

        </div>
      </div> -->

      <div class="erp info" data-aos="fade-in">
        <div class="info-title">
          Software de gestión
        </div>
        <div class="list">
          <h2>Contabilidad</h2>
          <h2>Gestión de proyectos</h2>
          <h2>Recursos humanos</h2>
          <h2>Gestión empresarial</h2>
        </div>
      </div>

      <div class="iconsouth " data-aos="fade-in">
        <mat-icon class="icon-south">south</mat-icon>
      </div>

      <div class="result info" data-aos="fade-up" data-aos-duration="1500">
        <div class="result-item"><i class="bi bi-graph-up text-gradient"></i>
          <div>Incremento de ventas</div>
        </div>
        <div class="result-item"><i class="bi bi-clock-history text-gradient"></i>
          <div>Optimización de los tiempos de trabajo</div>
        </div>
        <div class="result-item"><i class="bi bi-cash-stack text-gradient"></i>
          <div>Reduccion de costes</div>
        </div>
      </div>

    </div>
  </div>
</section>