import { Component, Input, OnInit } from '@angular/core';
import { ClientVoice } from 'src/app/models/clientvoice.interface';

@Component({
  selector: 'app-voice-card',
  templateUrl: './voice-card.component.html',
  styleUrls: ['./voice-card.component.css']
})
export class VoiceCardComponent implements OnInit {

  @Input() voice: ClientVoice;
  image_source: string

  constructor() { }

  ngOnInit(): void {
    this.image_source = "../../../assets/IMG/client/" + this.voice.image
  }

}
