<section>
  <div class="targets-container">
    <div *ngIf="!packs" class="d-flex w-100 justify-content-center align-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Cargando...</span>
      </div>
    </div>
    <div *ngFor="let pack of packs" class="targets-fondo">
      <h2 class="plan-name">{{ pack.name }}</h2>
      <div class="border-colored">
        <div class="target">
          <h4 class="prices">{{ getInteger(pack.price) }}'</h4>
          <h5 class="decimals">{{ getDecimal(pack.price) }}</h5>
          <h6 class="IVAinc">IVA inc</h6>
          <div class="vertical-divider" id="vertical-divider-4"></div>
          <p class="description-plan">
            <span *ngIf="pack.items.fiberSpeed">
              <mat-icon class="icons">wifi</mat-icon>Fibra simétrica de
              <strong
                >{{
                  !pack.items.fiberSpeed ? "" : pack.items.fiberSpeed
                }}MB</strong
              >
            </span>
            <br />
            <span *ngIf="pack.items.lines">
              <mat-icon class="icons">phone_android</mat-icon> +
              <strong>
                {{ pack.items.lines }}
                {{ pack.items.lines === 1 ? " Línea " : " Líneas " }}</strong
              >con
              <strong>{{
                pack.items.mobileData ? pack.items.mobileData : ""
              }}</strong>
            </span>
            <br />
            <ng-container *ngIf="pack.items.extra"></ng-container>
            <span *ngFor="let info of pack.items.extra">
              <mat-icon class="icons"> local_phone</mat-icon>+ {{ info }}
            </span>
            <br />
            <span *ngIf="pack.items.landline">
              <mat-icon class="icons"> local_phone</mat-icon>+ Fijo
            </span>
          </p>
          <div class="letter-container">
            <button
              class="lo-quiero"
              (click)="getTariffFromString(pack.name.toString())"
            >
              ¡Lo quiero!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
