<section>
    <div class="welcome">
        <h2 data-aos="fade-down" data-aos-duration="1500">BIENVENIDO A LA OPERADORA <strong style="color: #95c11f;">SIN
                HANDICAPS</strong></h2>
        <div class="divider" data-aos="fade-right" data-aos-duration="1500"></div>
    </div>

    <!--
    <div class="slide">
        <div class="subtitle color-blue">EMPRESAS</div>
        <mat-slide-toggle class="mat-slide" aria-label="Welcome" [(ngModel)]="checked" (change)="slideChange()"></mat-slide-toggle>
        <div class="subtitle color-pink">PARTICULARES</div>
    </div> -->
</section>