<!-- <mat-card>
    <p>{{packData.name}}</p>
    <p>{{packData.price}}</p>
    <p>{{!packData.items.lines ? '' : packData.items.lines }}</p>
    <p>{{!packData.items.fiberSpeed ? '' : packData.items.fiberSpeed }}</p>
    <p *ngIf="packData.items.landline">FIJO CON LLAMADAS ILIMITADAS</p>
    <p *ngIf="packData.items.mobileData">{{packData.items.mobileData}}</p>
    <button type="button" mat-raised-button color="accent" (click)="addToCart()">Añadir</button>
</mat-card>
 -->

<div class="targets-fondo">
  <div class="pack">
    <h2 class="plan-name">{{ packData.name }}</h2>
    <div class="target">
      <div class="main-price">
        <h2 class="prices">{{ getInteger(packData.price) }}'</h2>
        <div style="display: flex; flex-direction: column">
          <h5 class="decimals">{{ getDecimal(packData.price) }}</h5>
          <h6 class="IVAinc">IVA inc</h6>
        </div>
      </div>
      <div class="desc">
        <div class="vertical-divider" id="vertical-divider"></div>
        <div class="pack-description" id="pack-description">
          <span *ngIf="packData.items.fiberSpeed">
            <mat-icon class="icons">wifi</mat-icon>Fibra simétrica de
            <strong
              >{{
                !packData.items.fiberSpeed ? "" : packData.items.fiberSpeed
              }}MB</strong
            >
          </span>
          <span *ngIf="packData.items.lines">
            <mat-icon class="icons">phone_android</mat-icon> +
            <strong>
              {{ packData.items.lines }}
              {{ packData.items.lines === 1 ? " Línea " : " Líneas " }}</strong
            >con
            <strong>{{
              packData.items.mobileData ? packData.items.mobileData : ""
            }}</strong>
          </span>
          <span *ngIf="packData.items.landline">
            <mat-icon class="icons"> local_phone</mat-icon>+ Fijo
          </span>
          <ng-container *ngIf="packData.items.extra"></ng-container>
          <span *ngFor="let info of packData.items.extra">
            <mat-icon class="icons"> local_phone</mat-icon>+ {{ info }}
          </span>
        </div>
      </div>
      <div class="letter-container">
        <button class="lo-quiero" (click)="addToCart()">¡Lo quiero!</button>
      </div>
    </div>
  </div>
</div>
