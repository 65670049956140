import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { SelectorService } from 'src/app/services/selector.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  badgeHidden = true;
  badgeNumber = 0;
  constructor(
    private selectorService: SelectorService,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.selectorService.cart.subscribe((data) => {
      if (data.packs.length > 0 || data.cloudPBX) {
        this.badgeHidden = false;
        this.badgeNumber = data.packs.length;
        if (data.cloudPBX) this.badgeNumber++;
        return;
      }
      if (data.packs.length === 0) {
        this.badgeHidden = true;
        this.badgeNumber = 0;
      }
    });
  }
  toggleCart() {
    this.cartService.toggleCart();
  }
}
