<section>
    <div class="container-fluid voice-container">
        <div class="voice-title">
            <h2>Nuestros clientes <strong class="new-word" style="color:rgba(237, 17, 137, 1);">nos avalan</strong></h2>
            <div class="divider"></div>
        </div>
        <mat-accordion class="customer-voice">
            <app-voice-card [voice]='visible_voice' *ngFor='let visible_voice of visible_client_voice'></app-voice-card>
            <div class='expansion-toggle'>
                <mat-expansion-panel hideToggle class="expansion">
                    <mat-expansion-panel-header>
                        <div class="more">Ver todos</div>
                    </mat-expansion-panel-header>
                    <div class="customer-voice">
                        <app-voice-card [voice]='expansion_voice' *ngFor='let expansion_voice of expanded_client_voice'>
                        </app-voice-card>
                    </div>
                </mat-expansion-panel>
            </div>
        </mat-accordion>
    </div>
</section>