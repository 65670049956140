<section class="container-aboutus">

    <h1 class="main-title" data-aos="fade-down" data-aos-duration="2500">Nuestros <strong
            id="principios-word">principios</strong></h1>
    <div class="divider" data-aos="fade-left" data-aos-duration="2500"></div>

    <div class="aboutus-box">
        <div class="aboutus-boxes">
            <div class="title">
                <i class="bi bi-plus plus-icon"></i>
                <span>
                    Libertad
                </span>
            </div>
            <p>Con Golf Mobile eres libre, sin ataduras. <br> En Fibra + móvil son 12 meses.</p>
        </div>

        <div class="aboutus-boxes">
            <div class="title">
                <i class="bi bi-plus plus-icon"></i>
                <span>
                    Nos adaptamos
                </span>
            </div>
            <p> Tú decides lo que necesitas. <br> No pagues por servicios que no utilices.</p>
        </div>
        <div class="aboutus-boxes">
            <div class="title">
                <i class="bi bi-plus plus-icon"></i>
                <span>
                    Atención 24/7
                </span>
            </div>
            <p>A cualquier hora, cualquier día, para cualquier duda.</p>
        </div>

        <div class="aboutus-boxes">
            <div class="title">
                <i class="bi bi-plus plus-icon"></i>
                <span>
                    Sin cambios
                </span>
            </div>
            <p>El mismo precio en tu tarifa desde el primer día hasta el final.</p>
        </div>
    </div>

</section>