<section>
  <div class="bf-component">
    <div class="black-friday-text">
      <h1>En Golf Mobile estamos de Black Friday hasta Diciembre</h1>
      <p>¡Ofertas exclusivas!</p>
      <p>Contrata antes del 21/dic/21 y mantén el precio... ¡PARA SIEMPRE!</p>
    </div>
    <img
      src="../../../assets/IMG/black friday/chic_oferta_black_friday_nexo.png"
      class="girl-img"
    />
    <!--     <img class="title-bf black-friday-image" src="/assets/IMG/Black Friday/black_friday_1.png"
        alt="black friday tittle">-->
  </div>
</section>
