
<div></div>
    <section class="carousel" aria-label="Gallery">
        <ol class="carousel__viewport">
          <li id="carousel__slide1" tabindex="0" class="carousel__slide">
            <div class="Opiniones">
              <div><h3><strong>Ellesmere CM - Google Business</strong></h3></div>
              <div><p>Atención inigualable, grandes profesionales.
                Recomendable, se nota cuando las personas desempeñan sus funciones con pasión.
              </p></div>
              <div><h4>Diciembre 2020</h4></div>
            </div>

            <div class="carousel__snapper">
              <a href="#carousel__slide4" class="carousel__prev">Go to last slide</a>
              <a href="#carousel__slide2" class="carousel__next">Go to next slide</a>
            </div>
            </li>


          <li id="carousel__slide2" tabindex="0" class="carousel__slide">

            <div class="Opiniones">
              <h3>Manuel VAZQUEZ ORON - Google Business</h3>
              <p>Profesionales excelentes. Trato inmejorable. Precios muy competentes.
                Siempre disponibles y soluciones para todo. Muy recomendable.
              </p>
              <h4>Marzo 2020</h4>
            </div>

            <div class="carousel__snapper">
            <a href="#carousel__slide1" class="carousel__prev">Go to previous slide</a>
            <a href="#carousel__slide3"class="carousel__next">Go to next slide</a>
            </div>

          </li>


          <li id="carousel__slide3" tabindex="0" class="carousel__slide">

            <div class="Opiniones">
            <h3>Opinion en espera</h3>
            <p> PRUEBA
            </p>
            <h4>Marzo 2021</h4>
          </div>

            <div class="carousel__snapper">
            <a href="#carousel__slide2" class="carousel__prev">Go to previous slide</a>
            <a href="#carousel__slide4" class="carousel__next">Go to next slide</a>
            </div>
            
          </li>


          <li id="carousel__slide4" tabindex="0" class="carousel__slide">
            <div class="carousel__snapper"></div>
            <a href="#carousel__slide3" class="carousel__prev">Go to previous slide</a>
            <a href="#carousel__slide1" class="carousel__next">Go to first slide</a>
          </li>


        </ol>


        <aside class="carousel__navigation">
          <ol class="carousel__navigation-list">
            <li class="carousel__navigation-item">
              <a href="#carousel__slide1"class="carousel__navigation-button" >PRUEBA</a>
            </li>


            <li class="carousel__navigation-item">
              <a href="#carousel__slide2"class="carousel__navigation-button">Go to slide 2</a>
            </li>


            <li class="carousel__navigation-item">
              <a href="#carousel__slide3" class="carousel__navigation-button">Go to slide 3</a>
            </li>


            <li class="carousel__navigation-item">
              <a href="#carousel__slide4" class="carousel__navigation-button">Go to slide 4</a>
            </li>


          </ol>

        </aside>
      </section>


