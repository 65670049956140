import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avisolegal',
  templateUrl: './avisolegal.component.html',
  styleUrls: ['./avisolegal.component.css']
})
export class AvisolegalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
