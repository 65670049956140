import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-infoempresas",
  templateUrl: "./infoempresas.component.html",
  styleUrls: ["./infoempresas.component.scss"],
})
export class InfoempresasComponent implements OnInit {
  list1 = [
    {
      text: "Operadora automática",
      icon: "call_split",
    },
    {
      text: "Monitorización de colas",
      icon: "assistant",
    },
    {
      text: "Grupos de llamadas con salto a otras extensiones para cuando está ocupado",
      icon: "call",
    },
    {
      text: "Grabación de llamadas",
      icon: "mark_chat_unread",
    },
    {
      text: "Multiconferencia",
      icon: "settings_input_composite",
    },
    {
      text: "Informes de llamadas",
      icon: "inventory",
    },
    {
      text: "Mensajes personalizables al 100% (Voz, bienvenida, horario, etc)",
      icon: "construction",
    },
    {
      text: "Gestión de llamadas en espera",
      icon: "app_settings_alt",
    },
    {
      text: "Configuración según vuestros horarios y calendario",
      icon: "event_available",
    },
    {
      text: "Transferencia de llamadas",
      icon: "voicemail",
    },
    {
      text: "Ofrecemos música en espera personalizable y/o predeterminada",
      icon: "library_music",
    },
    {
      text: "Multidispositivo (app iOS/Android, ordenador, telefono IP)",
      icon: "devices",
    },
  ];

  list2 = [
    {
      text: "Call Center",
      icon: "settings_phone",
    },
    {
      text: "Llamada directa a extensión",
      icon: "settings_phone",
    },

    {
      text: "Buzón personalizable (avisos por email en tiempo real)",
      icon: "email",
    },
    {
      text: "Registro de llamada con filtro por destinos/horarios/fechas/extensiones",
      icon: "list_alt",
    },
    {
      text: "Base de datos de contactos común para todos los usuarios de la centralita (excel)",
      icon: "save",
    },
    {
      text: "Proporcionamos numeración de red inteligente (900/ 800)",
      icon: "dialer_sip",
    },
    {
      text: "Integración fijo-móvil",
      icon: "dialpad",
    },
    {
      text: "Soporte técnico atendido por personas 24/7",
      icon: "supervised_user_circle",
    },
    {
      text: "Horarios por grupos de llamadas y/o departamento",
      icon: "schedule",
    },
    {
      text: "Ofrecemos servicios de FAX de todo tipo",
      icon: "message",
    },
    {
      text: "Locuciones de bienvenida/triaje -> Fuera horario + buzones -> Selección por teclado",
      icon: "speaker_phone",
    },
    {
      text: "Histórico por defecto de las grabaciones de 3 meses (ampliable a 1 año)",
      icon: "featured_play_list",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
