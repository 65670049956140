import { Component, OnInit } from '@angular/core';
import { SlideToggleService } from 'src/app/services/slide-toggle.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  webType: string;
  constructor(private slideToggleService: SlideToggleService) {}

  ngOnInit(): void {
    this.slideToggleService.webType.subscribe((type) => (this.webType = type));
  }
}
