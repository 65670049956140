<section>
    <article class="politica-privacidad page type-page status-publish hentry">
        <div class="entry-content">
            <p>&nbsp;</p>
            <h1 class="titulo-pagina" style="text-align: center;line-height: 80px;">Política de privacidad</h1>
            <h2> </h2>
            <h2>¿Quién es el responsable del uso de sus datos?</h2>
            <p><strong>Titular:</strong> NEXO GLOBAL MANAGEMENT SL<br><strong> NIF:</strong>
                B97341499<br><strong>Domicilio
                    social</strong>: RONDA NARCIS MONTURIOL 4 EN PATERNA VALENCIA CP 46980<br><strong>Delegado de
                    protección
                    de datos (PDO)</strong>: INTEGRAL ADVICE TELECO S.L. CIF B44504868</p>
            <h2>1. Declaración de privacidad</h2>
            <p>NEXO es consciente de la importancia que para usted supone el uso que hacemos de su información
                personal y el modo en que la compartimos. Esta Política de privacidad tiene por objetivo el ayudarle a
                entender qué datos recopilamos, con qué finalidad los tratamos y nuestros esfuerzos por protegerlos.
                Apreciamos la confianza que deposita en nosotros para que los tratemos con las garantías adecuadas y de
                conformidad a la normativa vigente.</p>
            <p>Los datos personales que facilite a través de la página Web ubicada en la URL www.nexomobile.com (en
                adelante, la “Web”) serán tratados como se describe en detalle a continuación.</p>
            <h2>2. ¿De dónde procede y qué información tratamos?</h2>
            <ul class="list-default">
                <li>Datos que nos has facilitado directamente.</li>
            </ul>
            <p>Los datos que nos has facilitado directamente a través de los formularios de la Web, como por ejemplo
                nombre
                de usuario y contraseña, tu nombre y apellidos son con carácter general, obligatorios (salvo que en el
                campo
                requerido se especifique lo contrario) para cumplir con las finalidades establecidas. Por lo tanto, si
                no se
                facilitan los mismos o se facilitan incorrectamente no podrán atenderse las mismas, sin perjuicio de que
                podrá visualizar libremente el contenido del Sitio Web.</p>
            <ul class="list-default">
                <li>Datos recabados de forma automatizada a través de cookies.</li>
            </ul>
            <p>Igualmente recabamos datos de forma automatizada a través de cookies o dispositivos de almacenamiento y
                recuperación de datos sobre la navegación y uso que haces de nuestro sitio web, para saber el interés
                que
                generan nuestros contenidos y anuncios en redes sociales, correos electrónicos o SMS. Así como analizar
                los
                índices de respuesta mediante clics o comentarios, en nuestros eventos o publicaciones.</p>
            <h2>3. ¿Qué datos del usuario tratará NEXO?</h2>
            <p>NEXO tratará las siguientes categorías de datos del Usuario:</p>
            <ul class="list-default">
                <li>Datos identificativos: nombre, apellidos, DNI, dirección postal, dirección de correo electrónico,
                    teléfono, datos bancarios.</li>
                <li>Códigos o claves de identificación de usuario y/o cliente.</li>
                <li>Datos de características personales: fecha y lugar de nacimiento, sexo, edad, nacionalidad, estado
                    civil, datos de familia.</li>
                <li>Datos de navegación, localización, dirección IP, dirección MAC, navegador.</li>
            </ul>
            <p>En caso de que el Usuario facilite datos de terceros, manifiesta contar con el consentimiento de los
                mismos y
                se compromete a trasladarle la información contenida en la Política de Privacidad, eximiendo a
                NEXO de
                cualquier responsabilidad en este sentido. No obstante, NEXO podrá llevar a cabo las
                verificaciones
                periódicas para constatar este hecho, adoptando las medidas de diligencia debida que correspondan,
                conforme
                a la normativa de protección de datos.</p>
            <h2>4. ¿Con qué finalidad y cuál es la legitimación del tratamiento de los datos personales del usuario?
            </h2>
            <p>Los datos personales facilitados a través del Sitio Web serán tratados por NEXO, conforme a las
                siguientes finalidades</p>
            <ul class="list-default">
                <li>Finalidades basadas en el cumplimiento de la relación comercial o institucional:
                    <ul>
                        <li>Gestionar el registro del Usuario y permitir el acceso a las actividades y herramientas
                            disponibles a través del Sitio Web.</li>
                        <li>Llevar a cabo el alta de Usuario, así como el mantenimiento y la gestión de la relación con
                            NEXO.</li>
                        <li>Gestionar, tramitar y dar respuesta a peticiones, solicitudes, incidencias o consultas del
                            Usuario, cuando éste facilite sus datos a través de los formularios habilitados al efecto en
                            el
                            Sitio Web.</li>
                        <li>Analizar los datos de tráfico del Usuario que permita mantener la seguridad de las redes,
                            impidiendo intromisiones ilegítimas o la utilización fraudulenta de los servicios.</li>
                        <li>Gestionar el envío de la Newsletter.</li>
                    </ul>
                </li>
                <li>Finalidades basadas en el Interés Legítimo:
                    <ul>
                        <li>Realizar encuestas de satisfacción de cliente y estudios de mercado que tengan como
                            finalidad
                            evaluar la calidad de nuestros productos y servicios.</li>
                        <li>Gestionar el envío de comunicaciones comerciales propias o de terceros que puedan resultar
                            de su
                            interés, teniendo en cuenta los productos y servicios que tiene contratados con NEXO y
                            dentro de las expectativas razonables que tiene como Usuario, lo que nos permitirá poderle
                            ofrecer la mejor experiencia adaptada a sus necesidades.</li>
                        <li>la elaboración de un “perfil comercial” utilizando datos de consumo y facturación de los
                            productos y servicios contratados con NEXO.</li>
                        <li>Realizar análisis de solvencia del Usuario, consultando para ello sistemas de información
                            crediticia y ficheros de solvencia patrimonial y de crédito.</li>
                    </ul>
                </li>
                <li>Finalidades que requieren consentimiento explícito: NEXO solicitará el consentimiento expreso
                    e
                    inequívoco del Usuario con la finalidad de:
                    <ul>
                        <li>Realizar campañas comerciales que requieran la elaboración de un perfil comercial a partir
                            de
                            fuentes de datos externas a NEXO.</li>
                        <li>Utilizar datos de navegación tráfico y geolocalización del Usuario, con el fin de analizar
                            sus
                            preferencias y poder poner a su disposición ofertas comerciales personalizadas de productos
                            y
                            servicios propios y de terceros.</li>
                        <li>Comunicar los datos personales del Usuario a empresas participadas o del NEXO para que
                            pueda beneficiarse de la comercialización de sus productos.</li>
                    </ul>
                </li>
            </ul>
            <p>&nbsp;</p>
            <h2>5. ¿Durante cuánto tiempo tratará NEXO los datos del usuario?</h2>
            <p>NEXO tratará los datos personales del Usuario, en cumplimiento del principio de limitación del
                plazo de
                conservación, durante el tiempo necesario para la prestación de los servicios solicitados y los
                conservará
                hasta que nos manifieste su oposición o revoque su consentimiento. En estos casos, la información se
                mantendrá debidamente bloqueada sin que la podamos utilizar mientras sea necesaria para el ejercicio o
                defensa de reclamaciones legales o contractuales.</p>
            <p>Asimismo, en cumplimiento de las obligaciones derivadas de la Ley 25/2007, de 18 de octubre, de
                conservación
                de datos relativos a las comunicaciones electrónicas y a las redes públicas de comunicaciones NEXO
                conservará la información de tráfico y localización durante un plazo de 12 meses con el objetivo de
                cumplir
                con el deber de información en caso de recibir un requerimiento de la Autoridad Judicial.</p>
            <p>En los casos en que obtenemos datos automáticamente a través de cookies, podrá limitar su uso en el
                tiempo
                eliminándolas de los navegadores o dispositivos. Puede consultar instrucciones detalladas en nuestra <a
                    href="/cookies">Política de Cookies</a>.</p>
            <h2>6. ¿A qué destinatarios se comunicarán los datos del usuario?</h2>
            <p>Los datos del Usuario podrán ser comunicados en su caso a:</p>
            <ul class="list-default">
                <li>Las Administraciones Públicas en los casos previstos por la Ley.</li>
                <li>Las Fuerzas y Cuerpos de Seguridad del Estado y al Centro Nacional de Inteligencia en virtud de lo
                    establecido en la Ley.</li>
                <li>Los Bancos y Entidades Financieras para el cobro de los servicios ofrecidos.</li>
                <li>Otras empresas del grupo empresarial para fines administrativos internos y la gestión de los
                    productos y
                    servicios contratados.</li>
                <li>Otras empresas participadas y del NEXO con la finalidad de que sean tratados por éstas para
                    remitirte comunicaciones comerciales, incluso por medios electrónicos, sobre productos y servicios
                    que
                    puedan resultar de tu interés, siempre que nos autorices marcando la casilla habilitada al efecto.
                </li>
                <li>Compañías con las que NEXO colabora en los siguientes sectores: telecomunicaciones,
                    financieros,
                    seguros, tecnología, energía, audiovisual, seguridad, educación, ocio, automoción, publicidad y gran
                    consumo, con la finalidad de poder facilitarte, por medios electrónicos y/o convencionales,
                    información
                    comercial adaptada a tus gustos y preferencias, relacionada con tú ubicación actual y/o histórica,
                    siempre que dispongamos de tu consentimiento previo para comunicar los datos necesarios para esta
                    finalidad.</li>
                <li>Los Registros Públicos de Solvencia Patrimonial y los Sistemas de Prevención contra el Fraude, a los
                    que
                    podrán ser comunicados los datos relativos a impagos e incidencias en el pago de productos o
                    servicios
                    contratados a NEXO o a terceros.</li>
                <li>Proveedores y distribuidores, ubicados fuera del Espacio Económico Europeo, los cuales prestan a
                    NEXO servicios de Atención Telefónica a Clientes y de comercialización de los productos y
                    servicios de NEXO. Para efectuar dichas transferencias internacionales, NEXO cuenta con
                    la
                    debida autorización de la Agencia Española de Protección de Datos garantizándose adecuadamente el
                    tratamiento de los datos para estas finalidades.</li>
            </ul>
            <h3>Responsabilidad del usuario.</h3>
            <p>El Usuario:</p>
            <ul class="list-default">
                <li>Garantiza que los datos que facilita a NEXO son verdaderos, exactos, completos y actualizados.
                    A
                    estos efectos, el Usuario responde de la veracidad de todos los datos que comunique y mantendrá
                    convenientemente actualizada la información facilitada, de tal forma que responda a su situación
                    real.
                </li>
                <li>Será responsable de las informaciones falsas o inexactas que proporcione a través del Sitio Web y de
                    los
                    daños y perjuicios, directos o indirectos, que ello cause a NEXO o a terceros.</li>
            </ul>
            <p>&nbsp;</p>
            <h2>7. Comunicaciones comerciales y promocionales.</h2>
            <p>Una de las finalidades para las que NEXO trata los datos personales proporcionados por los Usuarios
                es
                para remitirles comunicaciones por correo electrónico o SMS con información relativa a productos,
                servicios,
                promociones y ofertas, según los casos en base al interés legítimo o a su consentimiento.</p>
            <p>En caso de que el Usuario desee dejar de recibir este tipo de comunicaciones por parte de NEXO, o
                revocar el consentimiento otorgado, puede enviar la solicitud mediante correo electrónico a <a
                    href="mailto:atencion.cliente@nexoMobile.es" target="_blank"
                    rel="noopener noreferrer">atencion.cliente@nexomobile.<wbr>es</a> adjuntado copia de documento
                válido de
                identidad.</p>
            <p>&nbsp;</p>
            <h2>8. Ejercicio de derechos.</h2>
            <p>El Usuario podrá ejercer en cualquier momento y de manera gratuita, los siguientes derechos:</p>
            <ul class="list-default">
                <li>Derecho de acceso: tendrá derecho a obtener confirmación de si se están tratando o no datos
                    personales
                    que le conciernen.</li>
                <li>Derecho de rectificación: tendrá derecho a obtener la rectificación de los datos inexactos o
                    incompletos
                    que le conciernan.</li>
                <li>Derecho de supresión: tendrá derecho a obtener la supresión de los datos personales que le
                    conciernan
                    cuando ya no sean necesarios en relación con los fines para los que fueron recabados.</li>
                <li>Derecho a la portabilidad de sus datos así como a la limitación del tratamiento, en los supuestos
                    previstos en la normativa de protección de datos.</li>
                <li>Derecho de oposición: tendrá derecho a oponerse al tratamiento de sus datos. NEXO dejará de
                    tratar
                    los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles
                    reclamaciones.</li>
                <li>Obtener intervención humana, a expresar tu punto de vista y a impugnar las decisiones automatizadas
                    a
                    adoptadas por parte de NEXO.</li>
            </ul>
            <p>&nbsp;</p>
            <h2>9. Medidas de seguridad.</h2>
            <p>La seguridad de la información es uno de nuestros firmes compromisos y en cumplimiento de la legislación
                vigente NEXO tratará los datos del Usuario en todo momento de forma absolutamente confidencial y
                guardando el preceptivo deber de secreto respecto de los mismos, adoptando al efecto las medidas de
                seguridad de índole técnica y organizativas necesarias que garanticen la seguridad de sus datos y eviten
                su
                alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la
                naturaleza de los datos almacenados y los riesgos a que están expuestos.</p>
        </div>
    </article>

    <article class="politica-cookies page type-page status-publish hentry">
        <div class="entry-content">
            <h1 class="titulo" style="text-align: center;">Política de cookies</h1>
            <p>Una&nbsp;<em>cookie</em>&nbsp;es un pequeño fichero de texto que se almacena en su navegador cuando
                visita
                casi cualquier página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a
                navegar
                por esa página. Las&nbsp;<em>cookies</em>&nbsp;suelen almacenar información de carácter técnico,
                preferencias personales, personalización de contenidos, estadísticas de uso, enlaces a redes sociales,
                acceso a cuentas de usuario, etc. El objetivo de la&nbsp;<em>cookie</em>&nbsp;es adaptar el contenido de
                la
                web a su perfil y necesidades, sin&nbsp;<em>cookies</em> los servicios ofrecidos por cualquier página se
                verían mermados notablemente.</p>
            <h2>Cookies utilizadas en este sitio web</h2>
            <p>Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a detallar el uso
                de&nbsp;<em>cookies</em>&nbsp;que hace esta web con el fin de informarle con la máxima exactitud
                posible.
            </p>
            <p>Este sitio web utiliza las siguientes&nbsp;<strong>cookies propias</strong>:</p>
            <ul>
                <li>Cookies de sesión, para garantizar que los usuarios que escriban en los formularios o en el chat
                    sean
                    humanos y no aplicaciones automatizadas. De esta forma se combate el <em>spam</em>.</li>
            </ul>
            <p>Este sitio web utiliza las siguientes&nbsp;<strong>cookies de terceros</strong>:</p>
            <ul>
                <li>Google Analytics: Almacena&nbsp;<em>cookies</em>&nbsp;para poder elaborar estadísticas sobre el
                    tráfico
                    y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de
                    información acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido
                    deberá hacerlo comunicando directamente con Google.</li>
                <li>Redes sociales: Cada red social utiliza sus propias&nbsp;<em>cookies</em>&nbsp;para que usted pueda
                    pinchar en botones del tipo&nbsp;<em>Me gusta</em>&nbsp;o&nbsp;<em>Compartir</em>.</li>
            </ul>
            <h2>Desactivación o eliminación de cookies</h2>
            <p>En cualquier momento podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio
                web.
                Estas acciones se realizan de forma diferente en función del navegador que esté usando.</p>
            <h2>Notas adicionales</h2>
            <ul>
                <li>Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad
                    de
                    las políticas de privacidad que puedan tener los terceros mencionados en esta política
                    de&nbsp;<em>cookies</em>.</li>
                <li>Los navegadores web son las herramientas encargadas de almacenar las&nbsp;<em>cookies</em>&nbsp;y
                    desde
                    este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus
                    representantes legales pueden garantizar la correcta o incorrecta manipulación de
                    las&nbsp;<em>cookies</em>&nbsp;por parte de los mencionados navegadores.</li>
                <li>En algunos casos es necesario instalar&nbsp;<em>cookies</em>&nbsp;para que el navegador no olvide su
                    decisión de no aceptación de las mismas.</li>
                <li>En el caso de las&nbsp;<em>cookies</em>&nbsp;de Google Analytics, esta empresa almacena
                    las&nbsp;<em>cookies</em> en servidores ubicados en Estados Unidos y se compromete a no compartirla
                    con
                    terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando
                    la
                    ley obligue a tal efecto. Según Google no guarda su dirección IP. Google Inc. es una compañía
                    adherida
                    al Acuerdo de Puerto Seguro que garantiza que todos los datos transferidos serán tratados con un
                    nivel
                    de protección acorde a la normativa europea. &nbsp;Si desea información sobre el uso que Google da a
                    las
                    cookies <a
                        href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es&amp;csw=1"
                        target="_blank" rel="noopener noreferrer">este enlace</a>.</li>
                <li>Para cualquier duda o consulta acerca de esta política de&nbsp;<em>cookies</em>&nbsp;no dude en
                    comunicarse con nosotros a través de la sección de contacto.</li>
            </ul>

        </div><!-- .entry-content -->


    </article>

    <article iclass="terminos-condiciones page type-page status-publish hentry">
        <div class="entry-content">
            <p>&nbsp;</p>
            <h1 class="titulo-pagina" style="text-align: center;line-height: 80px;">Términos y condiciones</h1>
            <p>&nbsp;</p>
            <p><span style="font-weight: 400;">Las presentes Condiciones Generales tienen por objeto regular las
                    condiciones
                    de uso de la plataforma de pago cuyo titular es NEXO GLOBAL MANAGEMENT S.L., con CIF B-97341499, con
                    domicilio fiscal en Calle Narcís Monturiol 4 edificio Destro, 46980, Paterna.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p><b>INFORMACIÓN GENERAL</b></p>
            <p><span style="font-weight: 400;">NEXO GLOBAL MANAGEMENT S.L., con CIF B-97341499, con domicilio fiscal en
                    Calle Narcís Monturiol 4 edificio Destro, 46980, Paterna es una empresa dedicada al sector de las
                    telecomunicaciones y telefonía móvil que opera en el ámbito nacional de España.&nbsp;&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p><b>TÉRMINOS DE LA COMPRA ON LINE</b></p>
            <p><span style="font-weight: 400;">El usuario debe tener al menos 18 años para poder utilizar y adquirir
                    cualquier producto o servicio de los ofrecidos por la web. DERECHO DE DESISTIMIENTO</span></p>
            <p><span style="font-weight: 400;">El cliente tiene derecho a desistir del presente contrato en un plazo de
                    14
                    días naturales sin necesidad de justificación.</span></p>
            <p><span style="font-weight: 400;">El plazo de desistimiento expirará a los 14 días naturales del día en que
                    se
                    le haya hecho entrega del bien o servicio.</span></p>
            <p><span style="font-weight: 400;">Para ejercer el derecho de desistimiento, el cliente deberá notificar su
                    decisión de desistir del contrato a través de una declaración inequívoca escribiendo un email a la
                    dirección </span><a href="mailto:contacto@nexomobile.es"><span
                        style="font-weight: 400;">contacto@nexomobile.es</span></a><span style="font-weight: 400;">
                    .&nbsp;</span></p>
            <p><span style="font-weight: 400;">Para cumplir el plazo de desistimiento, basta con que la comunicación
                    relativa al ejercicio por su parte de este derecho sea realizada antes de que venza el plazo
                    correspondiente.</span></p>
            <p>&nbsp;</p>
            <p><b>SEGURIDAD DE LA COMPRA ON LINE</b></p>
            <p><span style="font-weight: 400;">El sitio web utiliza técnicas de seguridad de la información generalmente
                    aceptada en la industria, tales como firewalls, procedimientos de control de acceso y mecanismos
                    criptográficos, todo ello con el objeto de evitar el acceso no autorizado de datos. Para lograr
                    estos
                    fines, el usuario/cliente acepta que el prestador obtenga datos para efecto de la correspondiente
                    autenticación de los controles de acceso.</span></p>
            <p><b>POLÍTICA DE ENTREGA</b></p>
            <p><span style="font-weight: 400;">El período de entrega del pedido podrá variar en función de las
                    siguientes
                    circunstancias:&nbsp;</span></p>
            <ul>
                <li style="font-weight: 400;"><span style="font-weight: 400;">Tipo de pedido (alta, migración,
                        portabilidad,
                        etc.)</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">Hora y día en que se registre la solicitud
                        en
                        el sistema.&nbsp;</span></li>
            </ul>
            <p><span style="font-weight: 400;">El pedido se enviará en un período de entre uno y tres días laborables
                    siempre que exista disponibilidad de este.&nbsp; En caso contrario, el usuario recibirá la
                    información
                    del tiempo estimado para realizar el envío.&nbsp;</span></p>
            <p><span style="font-weight: 400;">El pedido se entregará mediante mensajero en la dirección que se haya
                    indicado en el momento de contratación del producto.&nbsp; El pedido deberá ser recogido por la
                    persona
                    que haya realizado la solicitud.&nbsp; </span></p>

        </div><!-- .entry-content -->


    </article>

</section>