<footer>
  <div class="branding">
    <div class="info">
      <p>
        <a [href]="'/condiciones-contratacion'">Condiciones de Contratación</a>
      </p>
      <p><a [href]="'/aviso-legal'">Aviso legal</a></p>
      <p><a [href]="'/politica-de-privacidad'">Política de privacidad </a></p>
      <p>&nbsp;&nbsp;© 2022 Golf Mobile</p>
    </div>
    <img
      src="../../../assets/IMG/Logos-Golf/Logo_Golfmobile2.png"
      alt=""
      class="img-logo"
    />
  </div>
  <app-rrss></app-rrss>
</footer>
